import { createContext } from "react";

const AppContext = createContext({
  isFluid: true,
  isRTL: true,
  isDark: false,
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: "SR",
  isNavbarVerticalCollapsed: false,
});

export const AdminContext = createContext({
  userId: "",
  userStatus: "",
});

export default AppContext;
