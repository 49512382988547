export const FETCH_REQUEST_LIST = 'FETCH_REQUEST_LIST'
export const SET_REQUEST_LIST = 'SET_REQUEST_LIST'
export const FETCH_SINGLE_REQUEST = 'FETCH_SINGLE_REQUEST'
export const SET_SINGLE_REQUEST = 'SET_SINGLE_REQUEST'
export const ACCEPT_REQUEST = 'ACCEPT_REQUEST'
export const REJECT_REQUEST = 'REJECT_REQUEST'
export const FETCH_START = 'FETCH_START'
export const FETCH_SUCCESS = 'FETCH_SUCCESS'
export const FETCH_FAIL = 'FETCH_FAIL'
export const SET_REQUEST_PAGINATION = 'SET_REQUEST_PAGINATION'
