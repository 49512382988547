import React from "react";
import { keycloak } from "../../utils";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import Avatar from "../common/Avatar";
import img from "../../assets/img/icons/user.svg";

const ProfileDropdown = () => (
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav className="pr-0">
      <Avatar src={img} />
    </DropdownToggle>
    <DropdownMenu right className="dropdown-menu-card">
      <div className="bg-white rounded-soft py-2">
        <DropdownItem onClick={keycloak.logout}>تسجيل الخروج</DropdownItem>
      </div>
    </DropdownMenu>
  </UncontrolledDropdown>
);

export default ProfileDropdown;
