import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import Flex from "../common/Flex";
import Section from "../common/Section";

const AuthCardLayout = ({ children }) => {
  return (
    <Section fluid className="py-0">
      <Row noGutters className="min-vh-100 flex-center">
        <Col lg={6} className="col-xxl-5 py-3">
          <Card className="overflow-hidden z-index-1">
            <CardBody className="p-0">
              <Row noGutters className="h-100">
                <Col md={12} tag={Flex} align="center" justify="center">
                  <div className="p-4 p-md-5 flex-grow-1">{children}</div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Section>
  );
};
AuthCardLayout.propTypes = {
  leftSideContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default AuthCardLayout;
