import ar from "./ar.json";
import en from "./en.json";
// import { DEFAULT_LANGUAGE } from "../constants";
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector';

const LANGUAGES = Object.freeze({
    EN: 'en',
    AR: 'ar',
  })

const { EN, AR } = LANGUAGES

const options = {
  debug: process.env.NODE_ENV === 'local',
  lng: AR, // loadLocalStoreData('i18nextLng', AR, false),
  resources: {
    [AR]: {
      translation: { ...ar.ar }
    },
    [EN]: {
      translation: { ...en.en }
    }
  },
  fallbackLng: [AR, EN],
  ns: ['translation'],
  defaultNS: 'translation',

  react: {
    wait: false
  }
}

i18n.use(LanguageDetector).init(options)

export default i18n