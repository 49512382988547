import axios from "axios";
import { keycloak } from "../utils";
// import * as Sentry from "@sentry/browser";
// import { IS_PROD } from "../constants";
import APIs from "./apis";
import { API_URL } from "../constants";

const BASES_URLS = {
  DLL_BASE: API_URL.DLL,
};

const methods = {
  POST: "post",
  GET: "get",
  PUT: "put",
  DELETE: "delete",
};

const UNAUTHORIZED_STATUS = 401;
const FORBIDDEN_STATUS = 403
const UPDATE_TOKEN_VALIDITY = 30;

const prepareToken = () => keycloak.getToken();

axios.interceptors.request.use((config) => {
  keycloak.keycloak_instance
    .updateToken(UPDATE_TOKEN_VALIDITY)
    .success((res) => console.log("token updated", res)) // eslint-disable-line
    .error(keycloak.logout);
  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const err = error;

    if (error && error.response && error.response.status === UNAUTHORIZED_STATUS) {
      // signOut();
      // window.location = "/#/signin";
      keycloak.keycloak_instance
        .updateToken(UPDATE_TOKEN_VALIDITY)
        .success(() => axios(error.response.originalRequest))
        .error(keycloak.logout);
    }

    if (error && error.response && error.response.status === FORBIDDEN_STATUS) {
      setTimeout(() => keycloak.logout(), 4000)
    }

    return Promise.reject(err);
  }
);

const templateEngine = (template, data) => {
  const re = /{([^}]*)?}/;
  let match = re.exec(template);
  let tpl = template;
  while (match) {
    tpl = tpl.replace(match[0], data[match[1]] || "");
    match = re.exec(tpl);
  }
  return tpl;
};

const send = (method, url, d, customHeaders) => {
  if (!url) {
    throw new Error("URL PASSED TO THE REQUEST METHOD IS UNDEFINED. HINT: MAKE SURE THE SPELLING IS CORRECT");
  }
  let templateData = {};
  if (d instanceof FormData) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key of d.keys()) {
      templateData[key] = d.get(key);
    }
  } else {
    templateData = d;
  }

  const data = d || {};
  const fullURL = templateEngine(url, { ...templateData, ...BASES_URLS });
  const token = prepareToken(fullURL);
  const headers = token
    ? { Accept: "application/json", authorization: `Bearer ${token}`, ...customHeaders }
    : { Accept: "application/json", ...customHeaders };
  if (method === methods.POST || method === methods.PUT) {
    return axios[method](fullURL, data, { headers });
  }
  return axios[method](fullURL, { headers });
};

const sendPost = (url, data, headers) => send(methods.POST, url, data, headers);

const sendGet = (url, params) => send(methods.GET, url, params);

const sendPut = (url, data) => send(methods.PUT, url, data);

const sendDelete = (url, params) => send(methods.DELETE, url, params);

export const prepareURL = (url, d) => {
  const data = d || {};
  let fullURL = templateEngine(url, { ...data, ...BASES_URLS });
  const token = prepareToken(fullURL);
  fullURL = token ? `${fullURL}${url.indexOf("?") === -1 ? "?" : "&"}token=${token}` : token;
  return fullURL;
};

export const getErrors = (messages) => {
  const errors = [];
  if (messages) {
    Object.keys(messages).forEach((key) => {
      const catagory = messages[key];
      if (Array.isArray(catagory)) {
        catagory.forEach((msg) => {
          errors.push(msg);
        });
      } else {
        errors.push(catagory);
      }
    });
  }
  return errors;
};

export const request = {
  GET: sendGet,
  POST: sendPost,
  PUT: sendPut,
  DELETE: sendDelete,
};

export { APIs };
