import { createStore, applyMiddleware, compose } from 'redux'
import {rootReducer} from './reducers/index'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import requestsSaga from './sagas/requests_sagas'

function* rootSaga() {
    yield all([
        requestsSaga(),
    ])
  }

export const configureStore = () => {

const sagaMiddleware = createSagaMiddleware(rootSaga)

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(sagaMiddleware))
  )

sagaMiddleware.run(rootSaga)

return store
}