import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import { connect } from 'react-redux'
import {fetchRequests, acceptRequest, rejectRequest} from '../../store/actions/dashboardActions'

import FalconCardHeader from "../../components/common/FalconCardHeader";

import loadable from "@loadable/component";
import { useTranslation } from "react-i18next";

const LeasingPermissions = loadable(() => import("../../components/dashboard/LeasingPermissions"));

const Dashboard = (props) => {
  // State
  const [isSelected, setIsSelected] = useState(false);

  const { t } = useTranslation();
  const {reqs, pagination, fetching, acceptLeasingRequest, rejectLeasingRequest, fetchAllRequests} = props

  return (
    <Fragment>
      <Card className="mb-3">
        <FalconCardHeader title={t("orderList")} light={true} titleClass={"fs-1"}>
        </FalconCardHeader>
        <CardBody className="p-3">
          <LeasingPermissions fetching={fetching} acceptRequest={acceptLeasingRequest} rejectRequest={rejectLeasingRequest} requests={reqs} pagination={pagination} fetchAllRequests={fetchAllRequests} setIsSelected={setIsSelected} />
        </CardBody>
      </Card>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
      reqs: state.requestRducer.requests,
      pagination: state.requestRducer.pagination,
      fetching: state.requestRducer.fetching
    })

const mapDispatchToProps = (dispatch) => ({
  fetchAllRequests: (pageNumber, pageSize) => {
    dispatch(fetchRequests(pageNumber, pageSize))
  },
  acceptLeasingRequest: (id) => {
    dispatch(acceptRequest(id))
  },
  rejectLeasingRequest: (id) => {
    dispatch(rejectRequest(id))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
