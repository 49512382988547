import { version } from './config';
import { SSO_CONFIG } from './constants/config'

const ssoUrl = `${SSO_CONFIG.url}/realms/${SSO_CONFIG.realm}/account/`

export const homeRoutes = {
  name: 'إدارة طلبات التأجير',
  to: '/',
  exact: true,
  icon: 'chart-pie',
};

export const profileRoutes = {
  name: 'إعدادات الحساب',
  to: '',
  icon: 'cog',
};

export const logoutRoutes = {
  name: 'إدارة حساب واحد',
  to: ssoUrl,
  icon: 'lock',
};

export default [
  homeRoutes,
];
