import React, { Fragment} from "react";
import { toast } from 'react-toastify';
import i18n from 'i18next'

export const showToast = (content, type) =>{
    switch (type) {
        case 'success':
            return (toast.info(
                <Fragment className="w-25">
                  <span  className="m-2 pl-3 fs-0">
                  {i18n.t(content)}
                    </span>
                </Fragment>
              ,{position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined}))
            case 'error':
            return (toast.error(
                <Fragment className="w-25">
                  <span  className="m-2 pl-3 fs-0">
                  {i18n.t(content)}
                    </span>
                </Fragment>
              ,{position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined}))
        default:
            return null
    }
   }