import Keycloak from "keycloak-js";

import { SSO_CONFIG } from "../constants/config";

export const keycloak_instance = Keycloak(SSO_CONFIG);

const MIN_VALIDITY_KEYCLOAK = 30;
const ONLOAD_OPTION = "login-required";

export const init = (onLoad = ONLOAD_OPTION) =>
  new Promise((resolve, reject) =>
    keycloak_instance
      .init({ onLoad, checkLoginIframe: false })
      .success(resolve)
      .error(reject)
  );

export const login = async (redirectUri) =>
  await keycloak_instance.login({ redirectUri: redirectUri || window.location.href });

export const checkSSO = async () => await init("check-sso");

export const logout = () =>
  new Promise((resolve, reject) =>
    keycloak_instance
      .logout()
      .success(resolve)
      .error(reject)
  );

export const getProfile = () =>
  new Promise((resolve, reject) =>
    keycloak_instance
      .loadUserInfo()
      .success(resolve)
      .error(reject)
  );

export const refreshToken = (seconds) =>
  new Promise((resolve, reject) =>
    keycloak_instance
      .updateToken(seconds || MIN_VALIDITY_KEYCLOAK)
      .success((refreshed) => {
        console.log("refreshed token", refreshed);
        resolve(refreshed);
      })
      .error((err) => {
        console.log("refreshed token err", err);
        reject(err);
      })
  );

export const getToken = () => keycloak_instance.token;
