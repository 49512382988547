import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';
import { I18nextProvider } from 'react-i18next'
import i18n from './lang'
import {Provider} from 'react-redux'
import {configureStore} from './store/store'

const store = configureStore()

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Main>
        <App />
      </Main>        
    </Provider>
  </I18nextProvider>,
  document.getElementById('main')
);
