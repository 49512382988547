import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import Dashboard from "../screen/dashboard/dashboard"
import NavbarTop from "../components/navbar/NavbarTop";
import NavbarVertical from "../components/navbar/NavbarVertical";
import Footer from "../components/footer/Footer";
import loadable from "@loadable/component";
import AppContext from "../context/Context";

const DashboardRoutes = loadable(() => import("./DashboardRoutes"));

const DashboardLayout = ({ location }) => {
  const { isFluid } = useContext(AppContext);

  const body = document.getElementsByTagName('body')
  body[0].removeAttribute('class', 'dl-pdf-body')

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid ? "container-fluid" : "container"}>
      <NavbarVertical />
      <div className="content">
        <div className="min-vh-85">
          <NavbarTop />
          <Switch>
            <Route path="/" exact component={Dashboard} />
            <DashboardRoutes />
          </Switch>
        </div>
        <div className="mt-5">
          <Footer />
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;
