import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import PageNotFound from '../../assets/img/illustrations/404_page_not_found_.png';
import { useTranslation } from 'react-i18next'

const Error404 = () => {

const { t } = useTranslation()

return (
  <div className="text-center">
    
    <img src={PageNotFound} alt="" className="w-100" />
      <p className="lead mt-4 text-800 text-sans-serif font-weight-semi-bold">
      {t("pageNotFound")}
      </p>
      <hr />
      <p>
      {t("goBackToMain")}
      </p>
      <Link className="btn btn-info btn-sm mt-3" to="/">
        <FontAwesomeIcon icon="home" className="mr-2" />
        {t("goBackHome")}
      </Link>
  </div>
);
}

export default Error404;
