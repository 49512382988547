import React from "react";

import LogoutContent from "../components/auth/LogoutContent";
import AuthCardLayout from "../components/auth/AuthCardLayout";

const Logout = () => {
  return (
    <AuthCardLayout>
      <div className="text-center">
        <LogoutContent layout="card" titleTag="h3" />
      </div>
    </AuthCardLayout>
  );
};

export default Logout;
