import React from 'react';
import { Col, Row } from 'reactstrap';
import tamkeen from "../../assets/img/logos/Tamkeen_Logo_New.png"
import mhrsd from "../../assets/img/logos/MHRSD_Logo.png"
import takamol from "../../assets/img/logos/Takamol_Logo.png"
import { useTranslation } from 'react-i18next'


const Footer = () => {
  const { t } = useTranslation()
    return (

  <footer>
    <Row noGutters className="justify-content-between text-center fs--1 mb-3">
      <Col sm="auto">
      <img className="mr-2" src={tamkeen} alt="Logo" width={200} />
      </Col>
      <Col sm="auto">
      <p className="m-text-light m-text-medium "
                dangerouslySetInnerHTML={{
                  __html: t('footer')
                }} />
      </Col>
      <Col sm="auto">
      <img className="mr-2" src={takamol} alt="Logo" width={140} />
      <img className="mr-2" src={mhrsd} alt="Logo" width={90} />
      </Col>
    </Row>
  </footer>
)};

export default Footer;
