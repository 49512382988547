import * as actionTypes from '../actions/actionTypes'

const initialState = Object.freeze({
  fetching: false,
  requests: [],
  currentReq: {},
  error: null,
})

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_REQUEST_LIST:
      return { ...state, requests: [...action.payload] }
    case actionTypes.SET_SINGLE_REQUEST:
      return { ...state, currentReq: action.payload }
    case actionTypes.SET_REQUEST_PAGINATION:
      return { ...state, pagination: action.payload }
    case actionTypes.REJECT_REQUEST:
      return { ...state,  }
    case actionTypes.ACCEPT_REQUEST:
      return { ...state,  }
    case actionTypes.FETCH_START:
      return { ...state, fetching: true, error: null}
    case actionTypes.FETCH_SUCCESS:
      return { ...state, fetching: false, error: null}
    case actionTypes.FETCH_FAIL:
      return { ...state, fetching: false, error: action.payload }
    default:
      return state
  }
}
