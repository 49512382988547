import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { request, APIs } from "../../request";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import { pdfjs } from "react-pdf";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fetchRequest, setRequest } from "../../store/actions/dashboardActions";

class ViewImage extends Component {
  constructor(props) {
    super(props);
    const root = document.getElementsByTagName("html");
    const body = document.getElementsByTagName("body");

    body[0].setAttribute("class", "dl-pdf-body");
  }

  state = {
    numPages: 1,
  };

  componentDidMount() {
    this.props.fetchRequest(this.props.match.params.id);

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${
      pdfjs.version
    }/pdf.worker.js`;
  }

  componentWillUnmount() {
    this.props.emptyRequest();
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages: numPages });
  };

  render() {
    const { t, req } = this.props;

    return (
      <div className="d-flex flex-center flex-column justify-content-center m-3">
        {!req.licenceLinkBase64 ? (
          <div class="spinner-grow text-light spinner-grow-lg mb-3" role="status">
            <span class="sr-only ">Loading...</span>
          </div>
        ) : null}
        {req.licenceLinkBase64 && req.licenceLinkBase64.split(",")[0] === "data:application/pdf;base64" ? (
          <Document file={req.licenceLinkBase64} onLoadSuccess={this.onDocumentLoadSuccess}>
            {Array.from(new Array(this.state.numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} />
            ))}
          </Document>
        ) : (
          <img src={req.licenceLinkBase64} alt="" className="w-75 d-flex flex-center m-3" />
        )}
        <div className="d-flex flex-center flex-row justify-content-center">
          <button id="printbtn" className={`btn btn-info mr-1 m-5 mb-1 px-7`} onClick={() => window.print()}>
            <FontAwesomeIcon icon="print" transform="shrink-6" className="mr-1 fs-2 pt-1" />
            {t("printPage")}
          </button>
          <Link id="printbtn" className="btn btn-info mr-1 m-5 mb-1" to="/">
            {t("goBackToOrders")}
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  req: state.requestRducer.currentReq,
});

const mapDispatchToProps = (dispatch) => ({
  fetchRequest: (id) => dispatch(fetchRequest(id)),
  emptyRequest: (id) => dispatch(setRequest({})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ViewImage));
