import bell from '../../assets/img/icons/bell.png';

export const notifications = [
  {
    avatar: {
      src: bell,
      size: '2xl'
    },
    children: 'تم تفعيل <strong>فرع شركة الاجير المنتدب للاستقدام</strong> بنجاح',
    time: 'قبل لحظات',
    emoji: '',
    className: 'rounded-0 border-x-0 border-300 border-bottom-0',
    to: '#!',
    unread: true
  },
  {
    avatar: {
      src: bell,
      size: '2xl'
    },
    children: "قام <strong>مكتب مفاز للاستقدام</strong> بتعديل الطلب",
    time: '9 ساعات',
    emoji: '',
    className: 'rounded-0 border-x-0 border-300 border-bottom-0',
    to: '#!'
  }
];

export const rawNewNotifications = [
  {
    avatar: {
      src: bell,
      size: '2xl'
    },
    children: 'تم تفعيل <strong>فرع شركة الاجير المنتدب للاستقدام</strong> بنجاح',
    time: 'قبل لحظات',
    emoji: '',
    className: 'rounded-0 border-x-0 border-300 border-bottom-0',
    to: '#!',
    unread: true
  }
];

export const rawEarlierNotifications = [
  {
    avatar: {
      src: bell,
      size: '2xl'
    },
    children: "قام <strong>مكتب مفاز للاستقدام</strong> بتعديل الطلب",
    time: '9 ساعات',
    emoji: '',
    className: 'rounded-0 border-x-0 border-300 border-bottom-0',
    to: '#!'
  }
];
