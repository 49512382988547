import * as actionTypes from './actionTypes'


export const fetchRequests = (payload) => ({
    type: actionTypes.FETCH_REQUEST_LIST,
    payload
  })

export const setRequests = (payload) => ({
    type: actionTypes.SET_REQUEST_LIST,
    payload
  })

  export const setRequestPagination = (payload) => ({
    type: actionTypes.SET_REQUEST_PAGINATION,
    payload
  })
  
  export const fetchRequest = (payload) => ({
    type: actionTypes.FETCH_SINGLE_REQUEST,
    payload
  })

  export const setRequest = (payload) => ({
    type: actionTypes.SET_SINGLE_REQUEST,
    payload
  })
  
  export const acceptRequest = (payload) => ({
    type: actionTypes.ACCEPT_REQUEST,
    payload
  })
  
  export const rejectRequest = (payload) => ({
    type: actionTypes.REJECT_REQUEST,
    payload
  })
  
  export const fetchStart = () => ({
    type: actionTypes.FETCH_START
  })
  
  export const fetchSuccess = () => ({
    type: actionTypes.FETCH_SUCCESS
  })
  
  export const fetchFailed = (payload) => ({
    type: actionTypes.FETCH_FAIL,
    payload
  })
  