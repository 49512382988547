import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppContext, { AdminContext } from "./context/Context";
import toggleStylesheet from "./helpers/toggleStylesheet";
import { getItemFromStore, setItemToStore, themeColors } from "./helpers/utils";

const Main = (props) => {
  // Admin Context
  const [userId, setUserId] = useState();
  const [userStatus, setUserStatus] = useState();

  const adminValue = {
    userId,
    userStatus,
    setUserId,
    setUserStatus,
  };

  useEffect(() => {
    setItemToStore("userId", userId);
  }, [userId]);

  useEffect(() => {
    setItemToStore("userStatus", userStatus);
  }, [userStatus]);

  // App Context
  const [isFluid, setIsFluid] = useState(getItemFromStore("isFluid", true));
  const [isRTL, setIsRTL] = useState(getItemFromStore("isRTL", true));
  const [isDark, setIsDark] = useState(getItemFromStore("isDark", false));
  const [isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed] = useState(
    getItemFromStore("isNavbarVerticalCollapsed", false)
  );
  const [currency, setCurrency] = useState("SAR");
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const appValue = {
    isFluid,
    setIsFluid,
    isRTL,
    setIsRTL,
    isDark,
    setIsDark,
    showBurgerMenu,
    setShowBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    currency,
    setCurrency,
  };

  const setStylesheetMode = (mode) => {
    setIsLoaded(false);
    setItemToStore(mode, appValue[mode]);
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));
  };

  useEffect(() => {
    setStylesheetMode("isFluid");
    // eslint-disable-next-line
  }, [isFluid]);

  useEffect(() => {
    setStylesheetMode("isRTL");
    // eslint-disable-next-line
  }, [isRTL]);

  useEffect(() => {
    setStylesheetMode("isDark");
    // eslint-disable-next-line
  }, [isDark]);

  useEffect(() => {
    setItemToStore("isNavbarVerticalCollapsed", isNavbarVerticalCollapsed);
    // eslint-disable-next-line
  }, [isNavbarVerticalCollapsed]);

  if (!isLoaded) {
    toggleStylesheet({ isRTL, isDark }, () => setIsLoaded(true));

    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: isDark ? themeColors.dark : themeColors.light,
        }}
      />
    );
  }

  return (
    <AppContext.Provider value={appValue}>
      <AdminContext.Provider value={adminValue}>{props.children}</AdminContext.Provider>
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
