import env from "./env.json";

export const SSO_CONFIG = {
  url: env.SSO.AUTH_URL,
  realm: env.SSO.REALM,
  clientId: env.SSO.CLIENT_ID,
};

export const API_URL = {
  DLL: env.API.DLL,
};

export const is_dev = env.MODE === "dev" || env.MODE === "local";
export const is_staging = env.MODE === "staging";
export const is_uat = env.MODE === "uat";
export const is_prod = env.MODE === "prod";
