import React from "react";
import { Row} from "reactstrap";
import { useTranslation } from 'react-i18next'

const Loading = () => {
    const { t } = useTranslation()

    return (
    <div className={'min-vh-85 d-flex flex-column justify-content-center align-items-center container-fluid'}>
        <Row>
            <div class="spinner-grow text-primary spinner-grow-lg mb-3" role="status">
                <span class="sr-only ">Loading...</span>
            </div>
        </Row>
        {/* <Row>
            <p>{t('loading')}</p>
        </Row> */}
  </div>
  );
};

export default Loading;
