import * as actionTypes from '../actions/actionTypes'
import {put, call, takeLatest, all} from 'redux-saga/effects'
import {
    setRequests,
    setRequest,
    acceptRequest,
    rejectRequest,
    setRequestPagination,
    fetchStart,
    fetchSuccess,
    fetchFailed
} from '../actions/dashboardActions'
import { request, APIs } from "../../request";
import {showToast} from "../../components/shared/Toast"


function* fetchAllRequests(action) {
    yield put(fetchStart())
    try {
        const {pageNumber, pageSize} = action.payload
        const { data } = yield request.GET(APIs.DLL.LEASING_REQUESTS, {page: pageNumber, size: pageSize});
        yield put(setRequests(data.content))
        yield put(setRequestPagination(data))
        yield put(fetchSuccess())
    } catch(error) {
        yield put(fetchFailed(error))
    }
}

function* fetchLeasingRequest(action) {
    yield put(fetchStart())
    try {
        const { data } = yield request.GET(APIs.DLL.VIEW_REQUEST, {id: action.payload}); 
        yield put(setRequest(data))
        yield put(fetchSuccess())
    } catch(error) {
        yield put(fetchFailed(error))
    }
}

function* acceptLeasingRequest(action) {
    yield put(fetchStart())
    try {
        yield request.POST(APIs.DLL.ACCEPT_REQUEST, {id: action.payload});
        showToast('acceptReqSuccess','success')
        yield put(fetchSuccess())
      } catch (e) {
        if(e.response.data.messageCode !== 8){
        showToast(e.response.data.messageCode,'error')
        yield put(fetchFailed(e.response.data.messageCode))
      }
    }

}

function* rejectLeasingRequest(action) {
    yield put(fetchStart())
    try {
        yield request.POST(APIs.DLL.REJECT_REQUEST, {id: action.payload});
        showToast('rejectReqSuccess','success')
        yield put(fetchSuccess())
      } catch (e) {
        if(e.response.data.messageCode !== 8){
          showToast(e.response.data.messageCode,'error')
          yield put(fetchFailed(e.response.data.messageCode))
        }
      }

}

export default function* requestsSaga() {
    yield all([
      yield takeLatest(actionTypes.FETCH_REQUEST_LIST, fetchAllRequests),
      yield takeLatest(actionTypes.FETCH_SINGLE_REQUEST, fetchLeasingRequest),
      yield takeLatest(actionTypes.ACCEPT_REQUEST, acceptLeasingRequest),
      yield takeLatest(actionTypes.REJECT_REQUEST, rejectLeasingRequest),
    ])
  }