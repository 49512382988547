import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { keycloak } from "../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const LogoutContent = ({ titleTag: TitleTag }) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <TitleTag>{t("alert")}</TitleTag>
      <p className="mt-1">{t("notAuthorized")}</p>
      <Button onClick={keycloak.logout} color="primary" size="sm" className="mt-3">
        <FontAwesomeIcon icon="chevron-right" transform="shrink-4 down-1" className="mr-1" />
        {t("logout")}
      </Button>
    </Fragment>
  );
};

LogoutContent.propTypes = {
  layout: PropTypes.string,
  titleTag: PropTypes.string,
};

LogoutContent.defaultProps = {
  layout: "basic",
  titleTag: "h4",
};

export default LogoutContent;
