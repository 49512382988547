import React, { useEffect, useState, useCallback, useContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "./layouts/Layout";
import Loading from "./components/shared/Loading";
import Logout from "./screen/logout";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import "react-toastify/dist/ReactToastify.min.css";
import "react-datetime/css/react-datetime.css";
import "react-image-lightbox/style.css";
import { keycloak } from "./utils";
import { request, APIs } from "./request";
import { AdminContext } from "./context/Context";

const App = (props) => {
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { setUserId, setUserStatus } = useContext(AdminContext);

  const notAuthorized = props?.fetchingError === 403

  const renderMain = useCallback(
    () => (isAuthorized && !notAuthorized? <Layout /> : <Logout />),
    [isAuthorized]
  );

  useEffect(() => {
    const bootStrap = async () => {
      setLoading(true);
      try {
        const isLoggedIn = await keycloak.init();

        // SSO LOGGED IN
        if (isLoggedIn) {
          const { data } = await request.GET(APIs.DLL.LOGIN);
          const { userId, userStatus } = data;
          setUserId(userId);
          setUserStatus(userStatus);
          setLoading(false);
          setIsAuthorized(true);
        }
      } catch (e) {
        // console.log({ e });
        setIsAuthorized(false);
        setLoading(false);
      }
      setLoading(false);
    };
    bootStrap();
  }, []);
  return (
    <Router basename={process.env.PUBLIC_URL}>
      {!isLoading ? renderMain() : <Loading />}
    </Router>
  );
};

const mapStateToProps = (state) => ({
  fetchingError: state.requestRducer.error,
});

export default connect(mapStateToProps)(withTranslation()(App));
